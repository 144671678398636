<template>
  <div>
    <div v-if="$root.event.status != 'active'">
      <p style="width: 400px">
      <h4>Bu etkinlik sona ermiştir. İlginize teşekkür ederiz.</h4>
      <br />
      Etkinlikler ana sayfasına dönmek için
      <a href="https://etkinlikler.gskpro.com/tr-tr/ ">tıklayınız.</a>
      </p>
    </div>
    <div v-if="$root.event.status == 'active'">
      <div class="advers-video">
        <h6 style="margin-bottom: 8px">
          TÜFAM Farmakovijilans Bilgilendirme Videosu
        </h6>
        <div class="embed-responsive embed-responsive-16by9">   
          <iframe class="embed-responsive-item" id="advers-video" src="https://player.vimeo.com/video/590964577?loop=1&autoplay=0"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-xs-12">
          <div class="heading">
            <h6>Webinar Girişi</h6>
            <h2 class="event-title">{{ $root.event.title }}</h2>
            <p>
              <b>Konuşmacılar:</b> {{ $root.event.speakers }}<br />
              <b>Branşlar:</b> {{ getBranchStr($root.event) }}
            </p>
          </div>
        </div>
        <div class="col-md-4 col-xs-12" style=""></div>
      </div>
      <div class="row">
        <div class="col-md-8 col-xs-12">
          <div class="content-container card">
            <div class="card-body">
              <form class="card-text" role="form" @submit="handleTCID">
                <div class="form-group" style="margin-bottom: 48px">
                  <p style="color: red">
                    GSK tarafından düzenlenen/desteklenen bu web tabanlı
                    toplantıya kendi bilgisayarınızdan bağlanmanız, iki veya
                    daha fazla kişi bir araya gelerek katılmamanız hijyen ve
                    sağlık açısından büyük önem arz etmektedir.
                  </p>
                  <label for="tckimlikno">Sağlık Mesleği Mensubu kontrolü için lütfen TC Kimlik
                    Numaranızı giriniz</label>
                  <input id="tckimlikno" class="form-control form-control-lg" type="text" placeholder="TC Kimlik No"
                    v-model="tcId" required />
                </div>
                <p>
                  Şirketimiz GlaxoSmithKline İlaçları San. ve Tic. A.Ş. (“GSK”)
                  tarafından sağlanan web tabanlı toplantı hizmeti kapsamında;
                  03 Temmuz 2015 tarihli Resmi Gazetede yayınlanan “Beşeri Tıbbi
                  Ürünlerin Tanıtım Faaliyetleri Hakkında Yönetmelik” (zaman
                  zaman yapılabilecek ilgili tüm değişiklikleriyle birlikte
                  geçerli olmak üzere, “Yönetmelik” olarak anılacaktır)
                  çerçevesinde:
                </p>
                <div class="form-check">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="kvkk1" required />
                    <label class="custom-control-label" for="customCheck1">
                      <p>
                        Yukarıda ve bundan sonraki ekranlarda beyan ettiğim
                        şahsi bilgilerim ile ilgili, Türkiye İlaç ve Tıbbi Cihaz
                        Kurumu (“TİTCK”)’nun veri tabanında başvuru, giriş ve
                        bildirim işlemlerin yapılmasına ve bu platforma
                        paylaşılmasına; ve bu kapsamda sağladığım tüm kişisel
                        verilerimin kaydedilmesine, işlenmesine, gerekli olduğu
                        hallerde üçüncü kişilere transfer edilmesine 5 yıl süre
                        ile saklanılmasına; açık rıza verdiğimi kabul ve beyan
                        ederim.
                      </p>
                    </label>
                  </div>
                </div>

                <div class="form-check">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck2" v-model="kvkk2" required />
                    <label class="custom-control-label" for="customCheck2">
                      <a href="/img/W2W Kayıt Ekranı Onam Formu v2.pdf" target="_blank">Kişisel Verilerin Korunmasına
                        İlişkin Aydınlatma ve
                        Rıza Metni’ni</a>
                      okudum, onaylıyorum.
                    </label>
                  </div>
                </div>
                <div class="form-check">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="tufam" v-model="tufam" required />
                    <label class="custom-control-label" for="tufam">
                      TÜFAM farmakovijilans bilgilendirme videosunu izledim.
                    </label>
                  </div>
                </div>
                <button type="submit" class="btn btn-info btn-lg float-right" style="min-width: 120px">
                  İleri
                </button>
              </form>
            </div>
            <div class="card-body"></div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="tcIdModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Hata!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ errorMessage }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import errors from "@/errors"
import "bootstrap/js/dist/modal";
import _ from "lodash";
export default {
  data() {
    return {
      pageSettings: null,
      tcId: null,
      kvkk1: false,
      kvkk2: false,
      isReady: false,
      tufam: null,
      isError: false,
      errorMessage: "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
    };
  },
  name: "Landing",
  computed: {},
  methods: {
    getBranchStr(event) {
      let branchStr = "";
      let bArray = event.branches.split(",");

      _.forEach(bArray, (v, i) => {
        branchStr += this.$root.branchData[v].title;
        if (i != bArray.length - 1) {
          branchStr += ", ";
        }
      });

      return branchStr;
    },
    handleTCID(event) {
      event.preventDefault();
      if (!this.tcId || !this.kvkk1 || !this.kvkk2) {
        $("#tcIdModal").modal("show");
      } else {
        this.$axios
          .post("/checkConsent", {
            tcId: this.tcId,
            eventId: this.$root.event.id,
            deviceId: this.$root.device
          })
          .then(res => {
            if (res.data.eventUrl) {
              window.location.href = res.data.eventUrl;
            } else {
              this.$root.regId = res.data.regId;
              this.$router.push({ name: "live" });
            }
          })
          .catch(error => {
            if (error.response.data) {
              let status = error.response.data.message || error.response.data.status;
              if (status === "Invalid Webinar Id.") {
                this.$router.push({ name: "notready" });
              }
              if (status.includes(errors.CONSENT_NOT_FOUND.code)) {
                this.$root.tcId = this.tcId;
                this.$router.push(
                  "/event/" + this.$root.event.id + "/register"
                );
              } else if (status.includes(errors.SMM_REQUIRED.code)) {
                this.$router.push({ name: "smmrequired" });
              } else {
                this.errorMessage = "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin."; 
                $("#tcIdModal").modal("show");
              }
            }
          });
      }
    }
  },
  created() { },
  components: {
    Navbar
  }
};
</script>
<style lang="scss">
.advers-video {
  border: 1px solid #ececec;
  border-radius: 8px;
  padding: 8px;
  z-index: 1000;
  position: sticky;
  float: right;
  top: 20px;
  width: 33%;

  @media (max-width: 768px) {
    display: none;
  }
}
</style>
